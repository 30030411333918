.login-wrapper {
    width: 100%;
    height: 100%;

    .btn {
        padding: 8px 22px;
        font-size: 16px;
        width: 100%;

    }
}

.login-page {

    background: radial-gradient(126.11% 199.07% at 13.22% 6.16%, #14688A 0%, #0D3567 100%);
    opacity: 0.9;
}





.login-box,
.register-box {
    width: 100%;
    margin: 0;
    background-color: white;
    padding: 4rem;

    @media screen and (max-width:992px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}

.login-box-body,
.register-box-body {
    padding: 0;
    /*background-color: #143741;*/
    background-color: transparent;
}

.login-logo,
.register-logo {
    margin-bottom: 0px;
}

.login-box input.form-control,
.register-box input.form-control {
    border-width: 0;
    background-color: white !important;
    border-radius: 0;
    color: #666666;
    height: 45px;

}


.overlay-svg {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
}

.overlay-bg {
    z-index: -1;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    background: #ed1b2f;
    opacity: 0.8;



    @media screen and (max-width:992px) {
        height: 120px;
        min-height: 120px;
    }

}

.overlay-text {
    position: absolute;
    color: white;
    font-weight: 800;
    font-size: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    top: 50%;
    /* height: 100%; */
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    left: 50%;
    width: 100%;
    justify-content: center;

    @media screen and (max-width:992px) {
        font-size: 24px;
    }
}